<template>
  <div>
    <title>SIM INVENTORY ~ FINISH GOOD</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Finish Good Lists
        <br />
        <h4>The following is a list of finish good lists</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
              <li class="active">
                <a href="#tab_1" data-toggle="tab">Finish Good Lists</a>
              </li>
              <li>
                <a href="#tab_2" data-toggle="tab"
                  >Finish Good Items From External</a
                >
              </li>
              <li>
                <a href="#tab_3" data-toggle="tab"
                  >Finish Good Items From Internal</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tab_1">
                <div class="box-header">
                  <div class="box-body">
                    <div class="row">
                      <div class="col-xs-2">
                        Show &nbsp; :
                        <select
                          v-model="pageSize"
                          @change="handlePageSizeChange($event)"
                        >
                          <option
                            v-for="size in pageSizes"
                            :key="size"
                            :value="size"
                          >
                            {{ size }}
                          </option>
                        </select>
                        results
                      </div>
                      <div class="col-xs-5">
                        <input
                          type="text"
                          autocomplete="off"
                          placeholder="Search by Batch Code"
                          class="form-control"
                          v-model="namesearch"
                        />
                      </div>
                      <div class="col-xs-2">
                        <button
                          @click="searchdata()"
                          type="button"
                          class="btn btn-info"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                  <i class="fa fa-list-ul"></i>
                  <h3 class="box-title">Finish Good Lists</h3>
                </div>

                <!-- /.box-header -->
                <div v-if="isExist" class="box-body">
                  <div class="table-responsive">
                    <table
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <!-- <th>No.</th> -->
                          <!-- <th>Idx</th> -->
                          <th>Batch Code</th>
                          <th>Process Name</th>
                          <th>Process Results</th>
                          <th>Process Date</th>
                          <th>Process Out Results</th>
                          <th>Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(classdata) in list_paged"
                          :key="classdata.id"
                        >
                          <!-- <td>{{ index + 1 + (page - 1) * pageSize }}</td> -->

                          <!-- <td>{{ classdata.id }}</td> -->
                          <td>{{ classdata.nomor_produksi }}</td>
                          <td>{{ classdata.nama_proses }}</td>
                          <td>{{ classdata.hasil_proses }}</td>
                          <td>{{ classdata.tanggal_proses }}</td>
                          <td>{{ classdata.hasil_proses_out }}</td>
                          <td>{{ classdata.stok }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <v-pagination
                      v-model="page"
                      :pages="count"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="handlePageChange"
                    />
                  </div>
                </div>
                <div v-else class="box-body">
                  <div class="table-responsive">
                    <table
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <th>No.</th>
                          <!-- <th>Idx</th> -->
                          <th>Batch Code</th>
                          <th>Process Name</th>
                          <th>Process Results</th>
                          <th>Process Date</th>
                          <th>Process Out Results</th>
                          <th>Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="7">
                            <center>
                              <b style="color:red"> ... Data Not Found ... </b>
                            </center>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- /.box-body -->
              </div>
              <!-- /.tab-pane -->
              <div class="tab-pane" id="tab_2">
                <div class="box-header">
                  <div class="box-body">
                    <div class="row">
                      <div class="col-xs-2">
                        Show &nbsp; :
                        <select
                          v-model="pageSize2"
                          @change="handlePageSizeChange2($event)"
                        >
                          <option
                            v-for="size2 in pageSizes2"
                            :key="size2"
                            :value="size2"
                          >
                            {{ size2 }}
                          </option>
                        </select>
                        results
                      </div>
                      <div class="col-xs-5">
                        <input
                          type="text"
                          autocomplete="off"
                          placeholder="Search by Receipt Number"
                          class="form-control"
                          v-model="namesearch2"
                        />
                      </div>
                      <div class="col-xs-2">
                        <button
                          @click="searchdatafgfromexternal()"
                          type="button"
                          class="btn btn-info"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                  <i class="fa fa-list-ul"></i>
                  <h3 class="box-title">
                    Finish Good Items From External
                  </h3>
                </div>

                <!-- /.box-header -->
                <div v-if="isExist2" class="box-body">
                  <div class="table-responsive">
                    <table
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <!-- <th>No.</th> -->
                          <!-- <th>Idx</th> -->
                          <th>Receipt Number</th>
                          <th>Receipt Type</th>
                          <th>BL Number</th>
                          <th>Receipt Date</th>
                          <th>Register Number</th>
                          <th>Register Date</th>
                          <th>Supplier Name</th>
                          <th>Notes</th>
                          <th>Action</th>
                          <th>Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(classdata2) in list_paged2"
                          :key="classdata2.id"
                        >
                          <!-- <td>{{ index2 + 1 + (page - 1) * pageSize2 }}</td> -->
                          <!-- <td>{{ classdata2.idx }}</td> -->
                          <td>{{ classdata2.receipt_number }}</td>
                          <td>{{ classdata2.jenis_penerimaan }}</td>
                          <td>{{ classdata2.bl_number }}</td>
                          <td>{{ classdata2.receipt_date }}</td>
                          <td>{{ classdata2.bc_no }}</td>
                          <td>{{ classdata2.bc_date }}</td>
                          <td>{{ classdata2.nama_supplier }}</td>
                          <td>{{ classdata2.notes }}</td>

                          <td>
                            <router-link
                              :to="'/edit-finishgood/' + classdata2.id"
                            >
                              <button
                                title="Edit Finish Good"
                                class="btn btn-info"
                              >
                                <i class="fa fa-edit"></i>
                              </button>
                            </router-link>
                            <br />
                            <p></p>

                            <button
                              title="Delete finish good items"
                              @click="deleteData(classdata2.id)"
                              class="btn btn-danger"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                          <td>
                            <router-link
                              :to="
                                '/add-finishgood-detail/' +
                                  classdata2.auto_tpb +
                                  '/' +
                                  classdata2.bc_no +
                                  '/' +
                                  classdata2.receipt_number
                              "
                            >
                              <button
                                title="Add Details Finish Good Items"
                                class="btn btn-success"
                              >
                                <i class="fa fa-plus"></i>
                              </button>
                            </router-link>
                            <br />
                            <p></p>
                            <router-link
                              :to="
                                '/list-finishgood-detail/' +
                                  classdata2.receipt_number
                              "
                            >
                              <button
                                title="View Details Finish Good Items"
                                class="btn btn-info"
                              >
                                <i class="fa fa-list"></i>
                              </button>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <v-pagination
                      v-model="page"
                      :pages="count2"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="handlePageChange2"
                    />
                  </div>
                </div>

                <div v-else class="box-body">
                  <div class="table-responsive">
                    <table
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <!-- <th>No.</th> -->
                          <!-- <th>Idx</th> -->
                          <th>Receipt Number</th>
                          <th>Receipt Type</th>
                          <th>BL Number</th>
                          <th>Receipt Date</th>
                          <th>Register Number</th>
                          <th>Register Date</th>
                          <th>Supplier Name</th>
                          <th>Notes</th>
                          <th>Action</th>
                          <th>Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="10">
                            <center>
                              <b style="color:red"> ... Data Not Found ... </b>
                            </center>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- /.box-body -->
              </div>
              <!-- /.tab-pane -->
              <div class="tab-pane" id="tab_3">
                <div class="box-header">
                  <div class="box-body">
                    <div class="row">
                      <div class="col-xs-2">
                        Show &nbsp; :
                        <select
                          v-model="pageSize3"
                          @change="handlePageSizeChange3($event)"
                        >
                          <option
                            v-for="size3 in pageSizes3"
                            :key="size3"
                            :value="size3"
                          >
                            {{ size3 }}
                          </option>
                        </select>
                        results
                      </div>
                      <div class="col-xs-5">
                        <input
                          type="text"
                          autocomplete="off"
                          placeholder="Search by Batch Code"
                          class="form-control"
                          v-model="namesearch3"
                        />
                      </div>
                      <div class="col-xs-2">
                        <button
                          @click="searchdatafgfrominternal()"
                          type="button"
                          class="btn btn-info"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                  <i class="fa fa-list-ul"></i>
                  <h3 class="box-title">
                    Finish Good Detail Data From Internal
                  </h3>
                </div>

                <!-- /.box-header -->
                <div v-if="isExist3" class="box-body">
                  <div class="table-responsive">
                    <table
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <!-- <th>No.</th> -->
                          <th>Batch Code</th>
                          <th>Process Date</th>
                          <th>Stock</th>
                          <th>Process Results</th>
                          <th>Process Results Out</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(classdata3) in list_paged3"
                          :key="classdata3.id"
                        >
                          <!-- <td>{{ index3 + 1 + (page - 1) * pageSize3 }}</td> -->
                          <td>{{ classdata3.nomor_produksi }}</td>
                          <td>{{ classdata3.tanggal_proses }}</td>
                          <td>{{ classdata3.stok }}</td>
                          <td>{{ classdata3.hasil_proses }}</td>
                          <td>{{ classdata3.hasil_proses_out }}</td>
                          <td>
                            <router-link
                              :to="
                                '/edit-detailfinishgood-dariprosesproduksi/' +
                                  classdata3.id
                              "
                            >
                              <button
                                title="Edit Finish Good Details of the Production Process"
                                class="btn btn-info"
                              >
                                <i class="fa fa-edit"></i>
                              </button> </router-link
                            >&nbsp;
                            <!-- <button
                          title="Hapus Finish Good Detail dari Proses Produksi"
                          @click="deleteData(classdata3.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <v-pagination
                      v-model="page"
                      :pages="count3"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="handlePageChange3"
                    />
                  </div>
                </div>
                <div v-else class="box-body">
                  <div class="table-responsive">
                    <table
                      id="mydata"
                      class="table table-bordered table-striped"
                    >
                      <thead>
                        <tr>
                          <!-- <th>No.</th> -->
                          <th>Batch Code</th>
                          <th>Process Date</th>
                          <th>Stock</th>
                          <th>Process Results</th>
                          <th>Process Results Out</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="6">
                            <center>
                              <b style="color:red"> ... Data Not Found ... </b>
                            </center>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- /.box-body -->
              </div>
              <!-- /.tab-pane -->
            </div>
            <!-- /.tab-content -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "klasifikasiartikel",
  components: {
    VPagination
  },
  data() {
    return {
      nmsrc: true,
      loading: false,
      isExist: true,
      isExist2: true,
      isExist3: true,
      namesearch: "",
      namesearch2: "",
      namesearch3: "",
      list_paged: [],
      list_paged2: [],
      list_paged3: [],
      page: 1,
      pagestat: 1,
      count: 1,
      count2: 1,
      count3: 1,
      pageSize: 10,
      pageSize2: 10,
      pageSize3: 10,
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500],
      pageSizes3: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
    this.fetchdatafgfrom_eksternal();
    this.fetchDataFinishGoodDariProsesProduksi();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },

    handlePageChange2(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchdatafgfrom_eksternal();
    },

    handlePageSizeChange2(event) {
      this.pageSize2 = event.target.value;
      this.page = 1;
      this.fetchdatafgfrom_eksternal();
    },

    handlePageChange3(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchDataFinishGoodDariProsesProduksi();
    },

    handlePageSizeChange3(event) {
      this.pageSize3 = event.target.value;
      this.page = 1;
      this.fetchDataFinishGoodDariProsesProduksi();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchDataFinishGoodDariProsesProduksi() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget = this.$apiurl + "proses_produksi_d_dari_proses_produksi/getalldatastok_hasil_produksi?length=" + this.pageSize3 + "&page=" + this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          if(resp.data.data.length == 0) {
            this.isExist3 = false
          } else {
            this.isExist3 = true
            this.list_paged3 = resp.data.data;
            this.count3 = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    async fetchdatafgfrom_eksternal() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "penerimaan_fg_h/getalldatapenerimaan_fg_h?length=" +
        this.pageSize2 +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          if(resp.data.data.length == 0){
            this.isExist2 = false
          } else {
            this.isExist2 = true
            this.list_paged2 = resp.data.data;
            this.count2 = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "penerimaan_fg_d/getalldatastok_hasil_produksi?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          if(resp.data.data.length == 0) {
            this.isExist = false
          } else {
            this.isExist = true
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.loading = true;
        this.fetchData();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIget =
          this.$apiurl +
          "penerimaan_fg_d/getalldatastok_hasil_produksi?cari=" +
          this.namesearch +
          "&length=" +
          this.pageSize;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            })
          });
      }
    },
    searchdatafgfromexternal() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch2 == "") {
        this.loading = true;
        this.fetchdatafgfrom_eksternal();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIget =
          this.$apiurl +
          "penerimaan_fg_h/getalldatapenerimaan_fg_h?cari=" +
          this.namesearch2 +
          "&length=" +
          this.pageSize2;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged2 = resp.data.data;
            this.count2 = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            })
          });
      }
    },

    searchdatafgfrominternal() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch3 == "") {
        this.loading = true;
        this.fetchDataFinishGoodDariProsesProduksi();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIget =
          this.$apiurl +
          "proses_produksi_d_dari_proses_produksi/getalldatastok_hasil_produksi?cari=" +
          this.namesearch3 +
          "&length=" +
          this.pageSize3;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged3 = resp.data.data;
            this.count3 = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            })
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
